.g-budget {
  .p-budget-table {
    min-width: 1000px !important;
    .padding {
      padding-left: 10px !important;
    }
    .ui.input.p-input-inline > input {
      padding: 4px 7px !important;
    }

    th {
      white-space: normal !important;
    }

    .table-cell-category {
    }
    .table-cell-delete {
      width: 22px;
    }

    .table-cell-input {
      width: 145px;
      // max-width: 100px !important;
    }

    .table-cell-amounts {
      @media (max-width: 1500px) {
        width: 120px;
      }
      @media (min-width: 1501px) and (max-width: 1700px) {
        width: 135px;
      }
      @media (min-width: 1701px) {
        width: 150px;
      }
    }

    .table-cell-settings {
      width: 50px;
    }

    tr td:nth-child(8),
    tr th:nth-child(8) {
      border-right: 1px solid #f1f1f1;
      padding-right: 10px;
    }
  }

  .interactive-container {
    // min-width: 720px;
    width: 100%;
  }

  .interactive-budget {
    // min-width: 1000px;
    display: flex;
    overflow-x: auto;
    padding-bottom: 2rem;

    .interactive-budget-left {
      padding-right: 0.5em;
    }

    .interactive-budget-right {
      padding-left: 0.5em;
      padding-top: 0.5rem;
      flex-grow: 1;
    }

    .ui.card {
      width: 100% !important;
    }
  }
}
