.p-match-inline,
.inline-breakdown-dropdown {
  background-color: #f6f6f6;
  border-bottom: 1px solid #f6f6f6;
  padding: 2px 5px;
  border-radius: 4px;
  font-weight: 500;
  font-family: 'Inconsolata', monospace !important;
  &:hover {
    background-color: #fbfbfb;
    border-bottom: 1px dashed #ddd;
  }
  .menu .item {
    padding: 8px 10px !important;
    .text {
      font-size: 0.9em !important;
    }
  }
}

.p-dropdown-inline-edit {
  &.monospace > .text {
    font-size: 0.8em;
    font-family: 'Avenir' !important;
    font-weight: 600;
    display: block;
    text-align: left;
    color: #bcbcbc;
    text-transform: uppercase;
    margin-right: 0.5rem;
  }
  & .menu .item {
    padding: 8px 10px !important;
    .text {
      font-size: 0.8em;
    }
  }
}

.jump-dropdown {
  z-index: 100 !important;
  .item > .text {
    font-size: 1rem !important;
  }
}

#search-time-dropdown {
  margin-left: -1px;
  color: #666 !important;
  border: 1px solid #ddd;
  background-color: #fff !important;
  font-size: 0.9rem;
  padding: 10px;
  font-weight: 500;
  font-family: 'Inconsolata';

  &:hover,
  &:active,
  &:focus {
    border: 1px solid #fbb700;
    box-shadow: none;
  }

  .text {
    position: relative;
    top: -1px;
  }

  .item {
    padding: 10px;
    text-align: center;
    & > .text {
      font-size: 0.9rem;
      text-transform: uppercase;
      font-weight: 500;
    }
  }
}

.view-dropdown-csv {
  span.text {
    font-size: 1rem;
  }
}

.troubleshoot-dropdown {
  font-family: 'Inconsolata' !important;
  & > .text {
    font-size: 1.14rem !important;
  }
}

#editable-tags-dropdown {
  &.ui.fluid.search.selection.dropdown {
    & > div.text {
      top: 1px !important;
    }
  }
}

.category-dropdown {
  display: flex !important;
  justify-content: space-between;
  background-color: #fff;
  color: #bbb;
  border-radius: 4px;
  font-family: 'Inconsolata';
  min-height: unset;

  input.search {
    font-family: 'Inconsolata';
  }

  &.error {
    input {
      border-color: #e0b4b4 !important;
      background-color: #fff7f6 !important;
      &::placeholder {
        color: #9f3a38 !important;
      }
    }
  }

  & > .text {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .menu > .header {
    font-family: 'Avenir';
    font-size: 0.85rem;
  }

  &.p-category-inline,
  &.p-category-inline-new {
    border: 1px solid #ececec;
    border: 0;
    width: 100%;
    padding: 7px 11px; // because there is a border too

    /* States */
    &:hover {
      border-color: #a5a5a5;
    }

    &:focus {
      outline: none;
      border-color: #a5a5a5;
    }

    /* Elements */
    input.search,
    div.text {
      color: #666;
      font-size: 1rem !important;
    }
    input.search {
    }
    .menu > .item {
      max-width: 250px;
      display: block;
      font-size: 1rem !important;
      padding: 10px 14px !important;
      .text {
        white-space: nowrap;
        text-overflow: ellipsis;
        overflow: hidden;
      }
    }
  }

  &.p-category-modal {
    width: 100%;
    padding: 0.5em 0.75em !important;
    .message {
      font-size: 1.14rem;
    }
    &:not(.multiselect-search-dropdown) input.search {
      padding: inherit !important;
      top: 2px !important;
    }
    input.search,
    div.text {
      color: #666;
      font-size: 1.14rem !important;
      top: 4.5px !important;
    }
    /* Tags */
    &.multiple {
      padding: 0.25em 0.75em !important;
      justify-content: flex-start;
      & > input.search {
        margin: 0 !important;
      }
      & > .text {
        top: 1px !important;
        padding: unset !important;
      }
    }
    /* Asset, Tags */
    &.fluid {
      line-height: unset !important;
      & > div.text {
        margin-top: 1px;
      }
      .menu > .item {
        padding: 0.6em 0.75em !important;
        display: flex;
        flex-direction: column;
        // align-items: center;
      }
    }
    &.ui.fluid.search.selection.dropdown {
      & > div.text {
        top: 0.5px !important;
      }
    }
    // For editable tags
    &.ui.fluid.multiple.search.selection.dropdown {
      display: flex !important;
      flex-wrap: wrap;
    }
    div.text {
      top: 4px;
    }
    input.search {
      top: 2px !important;
    }
    .menu > .item {
      padding: 0.5em 0.75em !important;
    }
  }
}

.editable {
  // For seamless editing in inline
  .react-datepicker-wrapper {
    padding: 0 5px;
  }
}
.react-datepicker-wrapper {
  width: 100%;
  // padding: 6px;
  .react-datepicker__input-container {
    input {
      /* Common */
      border-radius: 4px;
      font-family: 'Inconsolata';
      border: 0px; //1px solid #dcdcdc;
      font-size: 1rem;

      /* States */
      &:hover {
        border-color: #a5a5a5 !important;
      }

      &:focus {
        outline: none;
        border-color: #a5a5a5 !important;
      }

      &.disabled {
        opacity: 0.45 !important;
      }

      &.error-datepicker {
        border-color: #e0b4b4 !important;
        background-color: #fff7f6 !important;
        &::placeholder {
          color: #9f3a38 !important;
        }
      }

      /* Specific locations */
      &.p-datepicker-inline {
        width: 100%;
        padding: 10px 7px;
        font-size: 1em;
      }
    }
  }
}

.react-datepicker-popper {
  z-index: 100 !important;
}

/**
* Add transaction dropdown
*/
.ui.dropdown.dropdown-menu {
  border: 0px !important;
  color: #fff !important;
  background-color: #f1b000 !important;

  &.active {
    background-color: #f1b000 !important;
  }

  .text {
    font-family: Avenir;
    font-size: 0.8em;
    font-weight: 600;
    text-transform: uppercase;
  }
}

/**
*  Currency dropdown
*/
.ui.button.dropdown.currency-dropdown {
  background-color: #fff;
  font-weight: 400;
  font-family: 'Inconsolata' !important;
  color: #666666;
  border-left: 1px solid #dcdcdc;

  input.search {
    outline: none;
    // Counteract the auto
    width: 100% !important;
  }

  &:hover {
    border-color: #a5a5a5;
    background-color: #fff;
  }

  &.p-dropdown-inline {
    padding: 7px 9px 6px 5px;
    input {
      padding: 7px 10px 9px 5px;
      font-family: 'Inconsolata' !important;
    }
    min-width: 0;

    // Icon
    & > .search.icon,
    & > .delete.icon,
    & > .dropdown.icon {
      top: -2px;
      right: -5px;
      margin: 0;
      padding: 0;
    }

    .menu .item {
      padding: 6px 10px;
      .text {
        font-size: 0.9em;
      }
    }
  }

  &.p-dropdown-modal {
    padding: 0.5em 0.75em !important;
    & .text {
      // width: 35px;
    }
    // Icon
    & > .search.icon,
    & > .delete.icon,
    & > .dropdown.icon {
      top: -1px;
      right: 0px;
      margin: 0;
      padding: 0;
    }
    &.currency-dropdown {
      border: 1px solid #dcdcdc;
    }

    .text {
      font-size: 1.14rem;
    }
  }

  /**
  *  Button card dropdown menu
  */

  &.p-dropdown-button {
    padding: 0.4em 0.4em !important;
    min-height: 0;
    max-width: 100%;
    white-space: nowrap;
    border: 1px solid #dcdcdc;

    /** Such that the transition from text to input is visually seamless */
    margin-left: -1px;

    &:hover {
      border-color: #a5a5a5;
    }

    .text {
      max-width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      font-style: normal !important;
      font-size: 1em;
    }

    .menu > .item {
      padding: 5px 10px !important;
    }
  }
}

// In rules
.ui.dropdown.view-dropdown {
  border: 1px solid $brand-yellow;
  padding: 6px 10px;
  border-radius: 4px;
  & > .text {
    font-weight: 700;
    color: $brand-yellow;
  }
  .icon {
    color: $brand-yellow;
  }
  .text {
    font-size: 1.14rem;
    font-family: 'Inconsolata', monospace;
  }
  .menu > .header {
    margin: 8px 0 5px 0;
  }
}

.ui.input.search-input {
  input {
    &:hover,
    &:focus {
      border-color: $brand-yellow;
    }
  }
}

.p-input-modal.error > input {
  border-color: #e0b4b4 !important;
  background-color: #fff7f6 !important;
  &::placeholder {
    color: #9f3a38 !important;
  }
}

.p-input-modal.disabled > input {
  opacity: 1 !important;
}

.multiselect-search-dropdown {
  input {
    padding-left: 0 !important;
    position: relative;
    top: 1px !important;
    left: -1px;
  }
  .default.text {
    margin-top: 0.575rem !important;
  }
}
