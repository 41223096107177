$dm-background-darkest: #0f0f0f;
$dm-background-darker: #1f1f1f;
$dm-background-dark: #202020; //151515
$dm-background: #272727; //#2d2d2d;
$dm-background-light: #2d2d2d;
$dm-background-lighter: #303030; ////#3f3f3f;
$dm-background-lightest: #4a4a4a;

$dm-border-color: #3d3d3d;
$dm-border-color-light: #4d4d4d;
$dm-border-color-lighter: #666;
$brand-green-light: #51b4a9;

$dm-text-dark: #6a6a6a;
$dm-text: #7a7a7a;
$dm-text-light: #8d8d8d;
$dm-text-lighter: #d2d2d2;
$dm-text-lightest: #ffffff;

@mixin no-outline {
  outline: none !important;
  box-shadow: none !important;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
  background-color: $dm-background-dark !important;
}

::-webkit-scrollbar {
  width: 5px;
  background-color: $dm-background-dark !important; //#eaeaea;
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  background-color: $dm-background-lightest !important; //#eaeaea;
}

.color--brand-green,
.suggested-recurring-button {
  color: #51b4a9 !important;
}

.color--grey {
  color: $dm-text-dark !important;
}

/* Loader */
.g-loading {
  background-color: $dm-background !important;
  background-image: url('../images/dark-bg.svg') !important;
  background-size: 91px 64px !important;
  .content {
    background-color: $dm-background-lighter !important;
    border-color: $dm-background-dark !important;
    box-shadow: none !important;
  }
}

/* Logged out */
.g-logged-out,
.g-locked-out {
  background-color: $dm-background !important;
  background-image: url('../images/dark-bg.svg') !important;
  background-size: 91px 64px !important;
  .g-logged-out-container,
  .g-locked-out-container {
    .locked-out-content,
    .logged-out-content,
    .register-container {
      @include no-outline;
      background-color: $dm-background-lighter !important;
      border-color: $dm-background-dark !important;
    }
  }
}

/* Trial banner */
.fixed-banner {
  // background-color: $dm-background-darker !important;
  a {
    color: $dm-text-lightest !important;
    opacity: 0.9;
  }
  border-bottom: 1px solid $dm-border-color !important;
}

/* Table container */
.table-container {
  @media (max-width: 1342px) {
    border-right-color: $dm-border-color-lighter;
  }
}

/* icon in transaction row */
.ui.selectable.table
  tbody
  tr:not(.g-empty-row):not(.editing):not(.no-hover-tr)
  td:hover,
.ui.table
  tbody
  tr
  td.selectable:not(.g-empty-row):not(.editing):not(.no-hover-tr)
  td:hover {
  i.green-on-hover {
    color: $dm-text-lightest !important;
  }
}

/* Links */
a,
.link {
  color: $brand-yellow !important;
}
.underline-link {
  color: $brand-yellow !important;
  border-bottom-color: $brand-yellow !important;
}

/* Header */
.ui.header {
  color: $dm-text-lightest;
}

/* Navigation */
/** Menu */
.ui.tabular.menu,
.ui.secondary.pointing.menu {
  box-shadow: none;
  border-bottom-color: $dm-border-color-light;
  a.item:not(.active) {
    color: $dm-text-light !important;
    &:hover {
      span.cog-icon {
        background-image: url('../images/cog-icon-light.svg');
      }
      color: $dm-text-lighter !important;
    }
  }
  a.active.item {
    span.cog-icon {
      background-image: url('../images/cog-icon-active.svg');
    }
    color: $brand-yellow;
    border-bottom-color: $brand-yellow;
    background-color: $dm-background;
    border-right-color: $dm-border-color-light;
    border-left-color: $dm-border-color-light;
  }
}

/* Budget expanded */
.ui.vertical.menu {
  background-color: $dm-background-lighter;
  border-color: $dm-background-lighter;
  i.icon.circle {
    color: $dm-border-color-lighter !important;
  }
  &.pointing > .item:hover {
    &::after {
      background-color: $dm-background-light !important;
    }
  }
  .item.header {
    border-bottom: 1px solid $dm-border-color-light;
  }
  .item:not(.active):not(.header):hover {
    background-color: $dm-background-light !important;
    color: $dm-text-lightest !important;
    i.icon {
      color: $dm-text-lighter !important;
    }
  }
  .item.active {
    background-color: $dm-background-light;
    &:after {
      background-color: $dm-background-light;
      border-top-color: $dm-background-light;
      border-right-color: $dm-background-light;
    }
  }
  .item:not(.active) {
    color: $dm-text-lighter !important;
  }
}

.ui.tabular.menu {
  background-color: $dm-background;
}

.ui.secondary.pointing.menu {
  background-color: $dm-background;
}

/* Nav */
.nav-mobile {
  .ui.menu.icon,
  .ui.vertical.menu.fluid.secondary {
    background-color: $dm-background !important;
    border-color: $dm-background-lighter !important;
    &:hover {
      background-color: $dm-background !important;
    }
    .item {
      background-color: $dm-background !important;
      &:hover {
        background-color: $dm-background !important;
      }
    }
  }
}

/* Pagination */
.pagination-amount,
.ui.pagination.menu {
  border-color: $dm-background !important;
  &.mini {
    background-color: $dm-background !important;
    border-color: $dm-background !important;
    .item,
    .choice {
      background-color: $dm-background !important;
      &.active {
        background-color: $dm-background-dark !important;
        color: $brand-yellow !important;
      }
      &:hover {
        background-color: $dm-background-dark !important;
      }
    }
  }
  .item,
  .choice {
    background-color: $dm-background-lighter !important;
    color: $dm-text-lighter !important;
    &.active {
      background-color: $dm-background !important;
      color: $brand-yellow !important;
    }
    &:hover {
      background-color: $dm-background-light !important;
    }
  }
}

/* Resizable columns */
.td-resize {
  background-color: #393939 !important;

  &:not(.no-hover):hover {
    background: #393939 !important;
  }

  .dragger {
    &:hover {
      background: #4d4d4d !important;
    }
    background: url('../images/column-resizer-dark.svg') repeat-y !important;
  }
}

.td-divider {
  background-color: #393939;
}

/* Bulk-select checkbox */
.bulk-checkbox {
  &.cta {
    background-image: url('../images/checkbox-empty-dark.svg');
    &:hover {
      background-image: url('../images/checkbox-empty.svg');
    }
  }
  &.deselect {
    background-image: url('../images/checkbox-deselect-dark.svg');
  }
  &.checked {
    background-image: url('../images/checkbox-checked-dark.svg');
  }
  &.empty {
    background-image: url('../images/checkbox-empty-dark.svg');
    &:hover {
      cursor: pointer;
      background-image: url('../images/checkbox-hover-dark.svg');
    }
  }
}

#quick-add-container {
  .transaction-details {
    padding: 1.5rem;
    border-radius: 4px;
    background-color: $dm-background-lighter !important;
    border-color: $dm-background-lighter !important;
  }
}

/* Modal */
// Lunch Money modal
.g-modal-container {
  background-color: rgba(0, 0, 0, 0.6);
  .modal {
    background-color: $dm-background;
    border-left-color: $dm-background;
    box-shadow: none;
    .modal-header {
      .header-text-large {
        color: $dm-text-lightest;
      }
    }
    .modal-footer {
      border-top-color: $dm-border-color-light !important ;
    }
    // Components
    h2 {
      color: $dm-text-lightest;
    }
    .transaction-details,
    .grouped-tx-row {
      background-color: $dm-background-lighter !important;
      border-color: $dm-background-lighter !important;
      h3 {
        color: $dm-text-lighter !important;
        border-bottom-color: $dm-border-color-lighter !important;
      }
      .monospace {
        color: $dm-text-lightest !important;
      }
      .transaction-detail {
        label:not(.file-btn) {
          color: $dm-text-light !important;
        }
        span:not(.file-box-content-filename) {
          color: $dm-text-lightest !important;
        }
      }
      // Checked checkbox
      .ui.toggle.checkbox input:checked ~ .box,
      .ui.toggle.checkbox input:checked ~ label {
        color: $dm-text-lightest !important;
      }
      .ui.toggle.checkbox input:not(:checked) ~ .box,
      .ui.toggle.checkbox input:not(:checked) ~ label {
        color: $dm-text-light !important;
      }
    }
  }
}
// Semantic Modal
.ui.modal.visible,
.ui.modals.visible {
  &.inverted.dimmer {
    background-color: rgba(0, 0, 0, 0.85) !important;
  }
  & > .header {
    background-color: $dm-background-lighter;
    color: $dm-text-lightest;
    border-bottom-color: $dm-border-color-lighter;
  }
  & > .content {
    background-color: $dm-background-lighter;
    .sub-header {
      border-bottom-color: $dm-border-color-lighter !important ;
    }
    .pre-footer {
      border-top-color: $dm-border-color-lighter !important;
    }
  }
  .actions {
    background-color: $dm-background;
  }
}

/* Message */
.ui.message {
  box-shadow: none !important;
  .link {
    border-bottom: 1px dashed $dm-text-light !important;
  }
  i.icon {
    color: $dm-text-lightest !important;
  }
  // default
  background-color: $dm-background-lightest;
  border-color: $dm-border-color-lighter;
  color: $dm-text-lighter;
  &.success {
    background-color: #166063; //2c7e2d;
    color: #fcfff5;
    border: 1px solid lighten(#166063, 40%);
    .header {
      color: #fcfff5;
    }
  }
  &.info {
    background-color: #2e4250; //276f86;
    color: #f8ffff;
    border: 1px solid lighten(#2e4250, 50%);
    .header {
      color: #f8ffff;
    }
    .link {
      color: $dm-text-lightest !important;
    }
  }
  &.error,
  &.negative {
    background-color: #b23a38;
    border: 1px solid lighten(#b23a38, 40%);
    color: #fff6f6;
    .header {
      color: #fff6f6;
    }
  }
  &.warning {
    background-color: #db8c2c;
    border: 1px solid lighten(#db8c2c, 40%);
    color: #fffaf3;
    .header {
      color: #fffaf3;
    }
  }
}

/* Popups */
.ui.popup.visible:not(.period-picker):not(.inverted) {
  @include no-outline;
  &.top {
    &:before {
      box-shadow: 1px 1px 0 0 $dm-background !important;
    }
  }
  &.bottom {
    &:before {
      box-shadow: -1px -1px 0 0 $dm-background !important;
    }
  }
  &.right.center {
    &:before {
      box-shadow: -1px 1px 0 0 $dm-background !important;
    }
  }
  &:before {
    background-color: $dm-background-darker !important;
  }
  background-color: $dm-background-darker !important;
  border-color: $dm-background-darker !important;
  color: $dm-text-lighter !important;
}

/* Card */
.ui.card {
  background-color: $dm-background-lighter !important;
  box-shadow: none !important;
  border-color: $dm-background-lighter !important;
  color: $dm-text-lighter !important;
  .content {
    border-top-color: $dm-border-color-lighter !important;

    .card-content-wrapper .card-content {
      .card-number,
      .card-text,
      span:not(.amount) {
        color: $dm-text-lighter;
      }
    }

    .transaction-details {
      background-color: $dm-background-lighter !important;
      border-color: $dm-background-lightest !important;
      &.selected {
        border-color: $brand-green-light !important;
        i.icon {
          color: $brand-green-light;
        }
        h3 {
          border-bottom-color: $brand-green-light !important;
        }
      }
      h3 {
        color: $dm-text-lighter !important;
        border-bottom-color: $dm-border-color-lighter !important;
      }
      .monospace {
        color: $dm-text-lightest !important;
      }
      .transaction-detail {
        label {
          color: $dm-text-light !important;
        }
        span {
          color: $dm-text-lightest !important;
        }
      }
    }
  }
}

/* Status bar */
.status-bar-background {
  background-color: $dm-background-dark;
  .today-line {
    background-color: $dm-background-lighter;
  }
}

/* Selectors (onboarding, recurring, modal) */
.link-recurring-row,
.help-row,
.setup-option {
  color: $dm-text-lighter !important;
  border-color: $dm-border-color-lighter !important;
  i.icon {
    color: $dm-border-color-lighter !important;
  }
  &.disabled {
    color: $dm-border-color-light !important;
  }
  &:hover:not(.disabled):not(.pending),
  &.selected,
  &.added,
  &.active {
    color: $brand-yellow !important;
    border-color: $brand-yellow !important;
    i.icon {
      color: $brand-yellow !important;
    }
  }
  span.note {
    color: $dm-text-lightest !important;
  }
}

/* Progress */
.ui.progress > .label {
  color: $dm-text-lighter;
}

/* Label */
.label-style {
  color: $dm-text-lighter !important;
}
.suggested-label {
  background-color: #44958c;
  color: $dm-background-darker; //#ddecea;
}

.label-cryptocurrency::before,
.label-bg-cryptocurrency {
  background-color: #be56e7;
}

.label-cryptocurrency {
  color: #be56e7 !important;
}

/* Divider */
p.divider {
  border-bottom-color: $dm-border-color-lighter;
}
p.divider-line {
  color: $dm-text-lighter;
  span {
    background-color: $dm-background-lighter;
  }
  &::before {
    border-top-color: $dm-border-color-lighter;
  }
}

/* Table */
.ui.table {
  background-color: $dm-background-lighter;
  border-color: $dm-background-lighter;
  box-shadow: none;
}
.ui.table
  tbody
  tr:nth-child(2n):not(.g-empty-row):not(.g-loader-row):not(.selected-row):not(.highlighted-row),
.ui.table tbody tr.pending,
.ui.table thead th:not(.td-resize),
.ui.table tfoot th {
  background-color: $dm-background-lighter !important;
}
.ui.table tbody tr.delete-pending {
  background-color: $dm-background-lighter !important;
  color: $red !important;
  &:hover {
    td {
      color: $red !important;
    }
  }
}
tr.padded-row.highlighted {
  background-color: $dm-background-light !important;
}
.rules-container-left {
  tr.highlighted,
  td:hover {
    color: $brand-yellow !important;
  }
}
.ui.table tbody tr.updated {
  animation-name: color-dark !important;
  @keyframes color-dark {
    0% {
      color: $dm-text-lighter;
      background-color: $dm-background-lighter;
    }
    20% {
      color: $dm-text-lightest;
      background-color: $dm-background-lightest;
    }
    100% {
      color: $dm-text-lighter;
      background-color: $dm-background-lighter;
    }
  }
}
.ui.sortable.table thead th:not(.no-hover):hover {
  color: $dm-text-lightest !important;
}
.ui.sortable.table thead th.sorted {
  color: $dm-text-lightest !important;
}
.ui.table tbody {
  color: $dm-text-lighter;
}
.ui.table:not(.unstackable) tr:not(:first-child) {
  border-top: 1px solid $dm-border-color-light !important;
}
.ui.table tr.recurring {
  color: $brand-green-light;
}
.ui.table tr td {
  border-top-color: $dm-border-color-light !important;
}
.ui.table thead th {
  border-bottom-color: $dm-border-color-light !important;
}
.ui.table tfoot th {
  border-top-color: $dm-border-color-light !important;
}
.ui.table tfoot {
  border-bottom-color: $dm-border-color-light !important;
}
.ui.selectable.table
  tbody
  tr:hover:not(.g-loader-row):not(.g-empty-row):not(.editing):not(.no-hover-tr):not(.selected-row),
.ui.table
  tbody
  tr:not(.selected-row)
  td.selectable:hover:not(.g-loader-row):not(.g-empty-row):not(.editing):not(.no-hover-tr) {
  background-color: $dm-background-light !important;
  &:not(.recurring) {
    color: $dm-text-lightest !important;
  }
  i.icon.angle.right {
    color: $dm-text-lightest !important;
  }
}
tr.selected-row {
  background-color: #072d2f !important;
}
.g-empty-row {
  background-image: url('../images/dark lines bg.svg') !important;
}

/* Input */
// Checkbox toggle button
.ui.toggle.checkbox input:checked ~ label::after {
  @include no-outline;
  background-color: $dm-text-lightest;
}
.ui.toggle.checkbox input:not(:checked) ~ label::after {
  @include no-outline;
  background-color: $dm-text-lighter;
}
// Checked checkbox
.ui.toggle.checkbox input:checked ~ .box,
.ui.toggle.checkbox input:checked ~ label {
  color: $dm-text-lighter !important;
}
// Unchecked checkbox
.ui.toggle.checkbox input:not(:checked) ~ label::before {
  background-color: $dm-background !important;
}
.ui.toggle.checkbox input:not(:checked) ~ .box,
.ui.toggle.checkbox input:not(:checked) ~ label {
  color: $dm-text-dark !important;
}
// Tag selector
.ui.multiple.search.selection.dropdown {
  .ui.label {
    background-color: $brand-yellow !important;
    color: $dm-background !important;
    border: none !important;
    box-shadow: none;
  }
}
.transaction-tag.short {
  background-color: $dm-background-lightest !important;
  border-color: $dm-background-lightest !important;
  color: $dm-text-lighter !important;
}
// Transactions - Button group with dropdown
.ui.buttons > .ui.dropdown {
  background: #f1b000 !important;
  border-color: #f1b000 !important;
  i.dropdown.icon {
    color: $dm-background;
  }
}
.ui.button {
  color: $dm-background;
  &:hover {
    color: $dm-background;
  }
  &.orange:not(.basic) {
    color: $dm-background !important;
  }
}
button.ui.tiny.button {
  &:not(.green) {
    background: $dm-background-darker !important;
    border-color: $dm-background-darker !important;
    color: $dm-text-lightest !important;
  }
  &.green {
    color: $dm-background-dark !important;
  }
}
// Dropdown
.ui.dropdown:not(.currency-dropdown) {
  background: $dm-background !important;
  border-color: $dm-background !important;
  &.darker-bg {
    & > .visible.menu {
      background: $dm-background-dark !important;
      .item:hover,
      .item.selected {
        background-color: $dm-background-light !important;
        color: $dm-text-lightest !important;
      }
    }
  }
  i.dropdown.icon {
    color: $dm-text-lightest;
  }
}
.currency-dropdown {
  background: $dm-background-dark !important;
  border: 0px !important;
}
// Dark dropdown backgrounds
.query-tool-container {
  .ui.dropdown > .visible.menu {
    background: $dm-background-dark !important;
    border-color: $dm-background-dark !important;
    .item:hover,
    .item.selected {
      background-color: $dm-background-light !important;
      color: $dm-text-lighter !important;
    }
  }
}
// Search bar in Transactions
.search-transactions {
  .ui.selection.dropdown {
    background-color: $dm-background-lighter !important;
    .visible.menu {
      background-color: $dm-background-light !important;
    }
  }
}
// Transaction filter row
.ui.dropdown.filter-row-dropdown {
  .disabled.item {
    color: $dm-text-lighter !important;
    background-color: $dm-background-dark !important;
    &:hover {
      background-color: $dm-background-dark !important;
    }
  }
}
.ui.dropdown:not(.category-dropdown) > .visible.menu {
  background: $dm-background-light !important;
  border-color: $dm-background !important;
  color: $dm-text-lighter !important;
  box-shadow: none;
  .header,
  .message {
    color: $dm-text-light;
  }
  .item {
    color: $dm-text-lighter !important;
  }
  .item:hover,
  .item.selected {
    background-color: $dm-background-dark !important;
    color: $dm-text-lightest !important;
  }
}
// EditableCategory
.category-dropdown {
  .item > .text > .text {
    color: $dm-text-lighter !important;
  }
  & > .visible.menu {
    background: $dm-background-darker !important;
    border-color: $dm-background-darker !important;
    color: $dm-text !important;
    box-shadow: none;
    .header,
    .message {
      color: $dm-text;
    }
    .item {
      color: $dm-text !important;
    }
    .item:hover,
    .item.selected {
      background-color: $dm-background-darkest !important;
      color: $dm-text-lightest !important;
    }
  }
}
.dropdown-category-group {
  opacity: 1 !important;
  background-color: $dm-background !important;
  &:hover {
    background-color: $dm-background !important;
  }
}
// Edit transaction group - Add dropdown
.ui.dropdown#search-dropdown {
  background: $dm-background-dark !important;
  border-color: $dm-background-dark !important;
  .visible.menu {
    background: $dm-background-dark !important;
    .item {
      background: $dm-background-dark !important;
    }
  }
}
// Transactions - Jump to month
.ui.dropdown.jump-dropdown,
.ui.dropdown.modal-dropdown {
  background: $dm-background-lighter !important;
  border-color: $dm-background-lighter !important;
  &:hover,
  &:active {
    background: $dm-background-light !important;
    border-color: $dm-background-light !important;
    box-shadow: none;
  }
  & > & .visible.menu,
  .header,
  .item {
    background: $dm-background-dark !important;
    border-color: $dm-background-dark !important;
  }
  i.icon {
    color: #fff;
  }
}
// EditableString
.g-editable-text .empty-state {
  border-color: $dm-background-light !important;
  color: $dm-text-dark;
  &:hover {
    border-color: $dm-background-dark !important;
  }
}
.editable-price-negative {
  border-color: $dm-background-lightest !important;
  &:not(.positive) {
    background-color: $dm-background-lightest !important;
  }
  color: $dm-text-lightest;
}
.ui.table tbody td.editable .g-editable-text:not(.selected):hover,
div.number-cell.editable .g-editable-text:not(.selected):hover {
  border: 1px solid #f8b500;
  background-color: $dm-background-dark !important;
}

.editable:hover {
  .g-editable-text:not(.selected) {
    .default-state {
      background-color: $dm-background-dark !important;
      border: 1px solid #f8b500;
    }
  }
}

td.expandable:hover {
  .g-editable-text {
    .readonly {
      background-color: #2d2d2d !important; // uneditable row hover color
      border: 1px solid #4d4d4d;
    }
  }
}

.g-editable-text.selected {
  border: 1px solid #f8b500;
  background-color: $dm-background-dark !important;
  // &:hover,
  .ui.dropdown {
    background-color: $dm-background-dark !important;
  }
  input,
  input:hover,
  input:active,
  input:focus {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
.ui.dropdown {
  input:focus {
    background-color: rgba(0, 0, 0, 0) !important;
  }
}
input[type='file'],
input[type='text'],
input[type='password'],
input[type='number'],
input[type='email'],
.StripeElement,
textarea {
  @include no-outline;
  &:not(.error-datepicker) {
    background-color: $dm-background;
    border-color: $dm-background !important;
    color: $dm-text-lighter !important;
    &::placeholder {
      color: $dm-text-dark !important;
    }
    &::selection {
      color: $dm-text-light !important;
    }
  }
  &:hover,
  &:active,
  &:focus {
    &::placeholder {
      color: $dm-text-dark !important;
    }
    background-color: $dm-background-dark !important;
    border-color: $dm-background-dark !important;
    color: $dm-text-lightest !important;
    box-shadow: none;
    outline: none;
  }
}
.ui.dropdown.error,
.ui.input.error,
input.error-datepicker {
  @include no-outline;
  background: $dm-background !important;
  input {
    background: $dm-background !important;
  }
  i.dropdown.icon {
    color: $dm-text-lightest;
  }
  border: 1px solid #b23a38 !important;
  border-color: #b23a38 !important;
  border-radius: 4px;
  &:hover {
    // Need both to override all types
    border: 1px solid $dm-background !important;
    border-color: $dm-background !important;
  }
}
.ui.dropdown:not(.add-to-cash-menu):not(.dropdown-menu) {
  outline: none !important;
  box-shadow: none !important;
  & > .text,
  & > .dropdown.icon {
    color: $dm-text-lighter !important;
  }
  &:active,
  &:focus {
    @include no-outline;
    &::placeholder {
      color: $dm-text-dark !important;
    }
  }
  &:hover {
    @include no-outline;
    background-color: $dm-background-dark !important;
    border-color: $dm-background-dark !important;
    cursor: pointer;
    & > input {
      background-color: $dm-background-dark !important;
      border-color: $dm-background-dark !important;
    }
    & > .text {
      color: $dm-text-lightest !important;
    }
  }
  .visible.menu {
    @include no-outline;
    .item {
      border-top-color: $dm-background-lighter;
    }
  }
}
// Transactions - Search transactions
.search-transactions,
.search-input {
  input,
  input:active {
    background-color: $dm-background-lighter !important;
    border-color: $dm-background-lighter !important;
    &::placeholder {
      color: $dm-text-dark;
    }
    &:hover {
      background-color: $dm-background-light !important;
      border-color: $dm-background-light !important;
      & ~ i.search.icon {
        color: #fff;
      }
    }
  }
  i.search.icon {
    color: #fff;
  }
}

/* Footer */
/** Icons */
#g-feedback,
#g-help,
#g-close,
#g-feature,
#g-aye {
  background-color: $dm-background-lighter;
  border-color: $dm-background;
  color: $dm-text-lighter;
  &.sample {
    background-color: $dm-background;
  }
  &:hover {
    background-color: $dm-background-lighter;
    border-color: $dm-background;
    color: $dm-text-lightest;
  }
}

/* Task cards */
.task-card {
  background-color: $dm-background-lighter;
  border-color: $dm-background-lighter;
  color: $dm-text-lightest;
  box-shadow: none;
  .task-text .title {
    color: $dm-text-lightest !important;
  }
  .task-checkmark {
    border-color: $dm-text-light !important;
    color: $dm-text-light !important;
  }
  &:hover {
    border-color: $dm-background-light !important;
    background-color: $dm-background-light !important;
    .task-text {
      color: $dm-text-lightest !important;
    }
    .task-checkmark {
      border-color: $dm-text-lightest !important;
      color: $dm-text-lightest !important;
      background-color: $dm-background-light !important;
    }
  }
}

/* Collaborators */
.collaborator,
.referred,
.purchased,
.token {
  p,
  span {
    color: $dm-text-lighter;
  }
}
.purchased {
  border-top-color: $dm-border-color-lighter;
}
.token {
  border-bottom-color: $dm-border-color-lighter !important;
}

/* Budgets table */
.p-budget-table {
  tr td:nth-child(8),
  tr th:nth-child(8) {
    border-right: 1px solid $dm-border-color-lighter;
  }
}

/* Help modal */
.rating-box {
  background-color: $dm-background;
  border-color: $dm-background;
}

/* Rules table rows */
.rules-container {
  .highlight-box {
    border-color: $dm-text-dark;
  }
}

/* Button card (expanded) */
.button-card {
  @include no-outline;
  background-color: $dm-background-lighter;
  border-color: $dm-background-lighter;
  &.disabled {
    .task-text > .title,
    .task-text > .subtitle {
      color: $dm-text-dark !important;
    }
    &:hover {
      border-color: $dm-background-lighter !important;
    }
  }
  &.selected {
    .task-text > .title {
      color: $dm-text-lightest !important;
    }
    .task-text > .subtitle {
      color: $dm-text-lighter !important;
    }
  }
  &:not(.selected):not(.disabled) {
    &:hover {
      border-color: $dm-background-light !important;
      background-color: $dm-background-light !important;
      .task-text > .title,
      .task-text > .subtitle {
        color: $dm-text-lightest !important;
      }
      .checkmark {
        border-color: $dm-text-lightest !important;
        color: $dm-text-lightest !important;
        background-color: $dm-background-light !important;
      }
    }
    .task-text > .title {
      color: $dm-text-lighter !important;
    }
    .task-text > .subtitle {
      color: $dm-text-light !important;
    }
    .checkmark {
      border-color: $dm-text-light !important;
      color: $dm-text-light !important;
      background-color: $dm-background-lighter !important;
    }
  }
}

/* Tabular table (Query Tool, Net Worth )*/
.header-buttons {
  .ui.active.button > i.icon {
    color: $dm-background-dark !important;
  }
}

// Period picker
.dropdown-mimic,
.switcher-button {
  &.on-bg {
    background-color: $dm-background;
    border-color: $dm-background;
  }
  background-color: $dm-background-light;
  border-color: $dm-background-light;
  color: $dm-text-lighter;
  .right-arrow,
  .left-arrow {
    border-color: $dm-background-lightest;
  }

  &:not(.disabled):hover {
    color: $dm-text-lightest;
    border-color: $dm-background-dark !important;
    background-color: $dm-background-dark !important;
    .main-dropdown {
      background-color: $dm-background-dark !important;
    }
  }
}
.query-tool-container,
.tabular-report {
  .header-controls {
    background-color: $dm-background-lighter;
    border-color: $dm-background-lighter;

    // Export
    i {
      color: $dm-text-lighter !important;
      &:hover {
        color: $dm-text-lightest !important;
      }
    }
  }

  #net-worth-chart {
    border-color: $dm-background-light !important;
    background-color: $dm-background-light !important;
  }
  #query-tool-chart {
    border-color: $dm-background-light !important;
    background-color: $dm-background-light !important;
  }

  .loading-card {
    background-color: $dm-background-light;
    border-color: $dm-background;
  }

  .report {
    .cell {
      border-radius: 0px !important;
      color: $dm-text-lighter !important;
      & > a:not(.color--green) {
        color: $dm-text-lighter !important;
      }
      a {
        color: $dm-text-lighter !important;
      }
      .color--green {
        color: $green !important;
      }
    }
    & > .left {
      border-color: $dm-border-color !important;
      border-right-color: $dm-border-color !important;
    }
    & > .center {
      border-top-color: $dm-border-color !important;
      border-right-color: $dm-border-color !important;
      border-bottom-color: $dm-border-color !important;
      i.add.circle.icon:hover {
        color: $dm-text-lightest !important;
      }
      .row:not(.header) {
        &:hover {
          .cell,
          .number-cell,
          .cell a,
          .number-cell a {
            background-color: $dm-background !important;
            &:not(.color--green) {
              color: $dm-text-lightest !important;
            }
          }
        }
      }
    }
    & > .right {
      border-top-color: $dm-border-color !important;
      border-right-color: $dm-border-color !important;
      border-bottom-color: $dm-border-color !important;
      .cell:hover,
      .cell:hover a {
        color: $dm-text-lightest !important;
      }
    }
    .row.header .cell {
      background-color: $dm-background-light !important;
      color: $dm-text-lighter !important;
      border-bottom-color: $dm-border-color-light !important;
    }
    .row.header .cell.active-sort {
      color: $dm-text-lightest !important;
    }
    .row:nth-child(odd):not(.footer-row):not(.footer):not(.primary):not(.secondary) {
      background-color: $dm-background-light !important;
      color: $dm-text-lighter !important;
    }
    .row:nth-child(even):not(.footer-row):not(.footer):not(.primary):not(.secondary) {
      background-color: $dm-background-lighter !important;
      color: $dm-text-lighter !important;
    }
    .border-bottom {
      border-bottom-color: $dm-border-color-light !important;
    }
    .row.primary {
      background-color: $dm-background-light !important;
    }
    .row.secondary {
      background-color: $dm-background-lighter !important;
    }
    .row.footer {
      background-color: $dm-background-light !important;
      color: $dm-text-lighter !important;
      .cell {
        border-top-color: $dm-border-color-light !important;
      }
    }
  }
}

/* Period picker (UI popup )*/
.overview-period-picker {
  border-color: $dm-background-lighter !important;
  background: $dm-background-lighter !important;
  color: $dm-text-lightest !important;
}
.ui.popup.period-picker {
  border-color: $dm-background-darker !important;
  background: $dm-background-darker !important;
  #period-picker {
    input {
      background-color: $dm-background-dark !important;
    }
    .period-option {
      color: $dm-text-lighter !important;
      border-bottom-color: $dm-border-color !important;
      &.selected span {
        color: $dm-text-lightest !important;
      }
      &:hover {
        background: $dm-background !important;
        span {
          color: $dm-text-lightest !important;
        }
      }
    }
  }
}

/* Spending breakdown (overview) */
i.icon.cog:hover {
  color: $dm-text-lightest !important;
}
.horizontal-bar-container {
  .label {
    color: $dm-text-lighter !important;
  }
  .horizontal-bar-row {
    background-color: $dm-background !important;
  }
}
.spending-breakdown-table {
  tbody tr:hover,
  .highlighted-row {
    background-color: $dm-background !important;
    .status-bar-background {
      background-color: $dm-background-darkest !important;
    }
  }

  span.excluded {
    color: $dm-text-light !important;
  }
}

.reorder-icon {
  background-image: url('../images/reorder-dark.svg') !important;

  &:hover {
    background-image: url('../images/reorder.svg') !important;
  }
}

tr.dragged-row {
  border: 1px dashed $dm-border-color-light !important;
  background-color: $dm-background-darker !important;
}

/* Table loader */
.g-loader-row {
  .loader-cell {
    background: url('../images/loader-bg-dark.svg') no-repeat;
  }
  &:hover,
  td:hover {
    background-color: $dm-background-lighter !important;
  }
}

/* CSV Importing */
.p-csv-import-table {
  tr {
    background-color: $dm-background-lighter !important;
    .droppable-table-cell {
      border: $dm-border-color-lighter !important;
      background-color: $dm-background-lightest !important;
      color: $dm-text-lighter !important;
    }
    .remove-button {
      border-color: $dm-border-color-lighter !important;
      &:hover {
        border-color: $red !important;
      }
    }
  }
}
.draggable-card {
  .ui.card {
  }
  &:hover {
    .ui.card {
      @include no-outline;
      background-color: $dm-background-light !important;
      border-color: $dm-background-light !important;
      color: $dm-text-lightest !important;
    }
  }
}

/* Tooltips */
#query-tool-tip,
#net-worth-tool-tip {
  background-color: $dm-background-dark !important;
  color: $dm-text-lighter !important;
  border: 1px solid $dm-background-dark;

  .header {
    color: $dm-text-lightest !important;
  }
  .label {
    color: $dm-text-lighter !important;
  }
  .tx {
    color: $dm-text-lighter !important;
    i.icon {
      color: $dm-text-light !important;
    }
  }
  .highlight-title {
    background-color: $dm-background-dark !important;
    color: $dm-text-lightest !important;
    border-radius: 0px !important;
    border-bottom: 1px solid $dm-border-color-light !important;
    margin-top: 5px;
    margin-bottom: 2px;
  }
  .number {
    color: $dm-text-lightest !important;
  }
}
#tooltip-budgeted,
#tooltip-spent {
  span.link.clickable {
    color: $dm-text-lightest !important;
  }
}
// Trends
.tooltip-year {
  background-color: $dm-background-dark !important;
  color: $dm-text-lighter !important;
  border: 1px solid $dm-background-dark;
  p.header-text {
    color: $dm-text-lightest !important;
  }
  .footer-text {
    a.link {
      color: #000 !important;
      background-color: #fbb700;
    }
  }
}

/** Loader */
.ui.loader {
  color: rgba(255, 255, 255, 0.9) !important;
}

/** Datepicker */
.react-datepicker-popper[data-placement^='top'] .react-datepicker__triangle {
  border-top-color: $dm-background-dark !important;
  &:before {
    border-top-color: $dm-background-dark !important;
  }
}
.react-datepicker {
  background-color: $dm-background-dark !important;
  border-color: $dm-background-dark !important;
  color: $dm-text !important;
  .react-datepicker__triangle {
    border-bottom-color: $dm-background !important;
    &:before {
      border-bottom-color: $dm-background-dark !important;
    }
  }
  .react-datepicker__header {
    border-bottom-color: $dm-border-color-lighter !important;
    background-color: $dm-background !important;
    padding-top: 6px !important;
    padding-bottom: 5px;
  }
  .react-datepicker__current-month,
  .react-datepicker__day-name,
  .react-datepicker__day,
  .react-datepicker-year-header {
    color: $dm-text-lighter !important;
  }
  .react-datepicker__day:hover,
  .react-datepicker__month-text:hover {
    background-color: $dm-background-light !important;
    color: $dm-text-lightest !important;
  }
  .react-datepicker__day--selected {
    background-color: $brand-yellow !important;
    color: $dm-background-dark !important;
  }
}

/* Developers */
.api-token {
  padding: 0 1rem 0 0 !important;
  border: 1px solid $dm-background !important;
  background-color: $dm-background !important;
}

/* Onboarding */
.g-onboarding {
  background-color: $dm-background !important;
  background-image: url('../images/dark-bg.svg') !important;
  background-size: 91px 64px !important;

  .setup-content {
    @include no-outline;
    background-color: $dm-background-lighter !important;
    border-color: $dm-background-dark !important;
  }

  .left-content {
    background-color: $dm-background-dark !important;
    background-image: url('../images/onboarding-subbg-dark.png');
  }

  .right-content {
    filter: brightness(0.8) contrast(1.2);
    background-color: $dm-background-lighter !important;
    h2 {
      color: $dm-text-lightest !important;
    }
    p {
      color: $dm-text-lighter !important;
    }
    .footer {
      border-top-color: $dm-border-color-light !important;
    }
    .task-card {
      border-color: $dm-border-color-lighter !important;
    }
  }
}

#g-stats,
#g-trends {
  .stats-header-bar {
    // Unchecked checkbox
    .ui.toggle.checkbox input:not(:checked) ~ label::before {
      background-color: $dm-background-dark !important;
    }
  }
  #stats-container {
    // Override the link color
    i.icon.color--grey {
      color: $dm-text-dark !important;
    }
  }
}

.gift-card-container {
  .gift-card {
    border: 1px solid $dm-border-color-light;
    .price {
      color: $dm-text-lightest;
    }
    .strike-out {
      color: $dm-text-dark;
    }
    .item {
      color: $dm-text-lighter;
    }
  }
}

// Auto-suggest

.react-autosuggest__suggestions-container--open {
  border-color: $dm-background-darker !important;
  background: $dm-background-darker !important;
  box-shadow: none;

  ul.react-autosuggest__suggestions-list {
    li {
      color: $dm-text-lighter !important;
      border-bottom-color: $dm-border-color !important;
      &.react-autosuggest__suggestion--highlighted {
        background: $dm-background !important;
        color: $dm-text-lightest !important;
      }
    }
  }
}

// Files
.file-box {
  border: 1px solid $dm-border-color-lighter;
  background-color: $dm-background-light;

  .file-box-icon:hover {
    background-color: $dm-background-light;
  }

  .file-box-content-filename {
    color: #fff !important;
    &:hover {
      color: $brand-green !important;
    }
  }
}

.drag-over {
  border: 10px dashed $dm-border-color-light !important;
  background-color: rgba(0, 0, 0, 0.85) !important;
}

#file-drop {
  border: 1px dashed $dm-border-color-lighter;
  background-color: $dm-background-light;

  .file-btn {
    color: #000 !important;
  }
}

#g-integrations {
  .integrations-container {
    .crypto-integration-box {
      border: 1px solid $dm-border-color-light;
      background-color: #eee;
      &:hover:not(.disabled),
      &.selected {
        border-color: $dm-border-color;
      }
    }
  }
}

#g-billing {
  .plan-details {
    border-color: $dm-border-color-light;
  }
}

// Range
input[type='range'] {
  background: transparent;
  height: 39px;
  -webkit-appearance: none;
  margin: 10px 0;
  width: 100%;
  border: 0px !important;
  padding: 0px !important;
}
input[type='range']::-webkit-slider-thumb {
  border-color: $dm-background-light;
}
input[type='range']::-moz-range-thumb {
  border-color: $dm-background-light;
}
input[type='range']::-ms-thumb {
  border-color: $dm-background-light;
}

// Calendar
.g-calendar {
  .cell-day {
    &:not(:first-child) {
      border-left-color: $dm-border-color-light !important;
    }
    .total {
      border-top-color: $dm-border-color-light !important;
    }
    &:not(.null-day):hover {
      background-color: $dm-background !important;
    }

    &.is-today {
      .display-day {
        color: #eee !important;
      }
    }
  }
}

.line-item > .underline {
  border-bottom: 1px dashed $dm-border-color-lighter !important;
}

.segment-container {
  background-color: $dm-background-light !important;
  border-color: $dm-border-color-lighter !important;
  h3 {
    color: $dm-text-lighter !important;
    border-bottom-color: $dm-border-color-lighter !important;
  }
}

// CSV import
.selection-container {
  background-color: $dm-background-lighter !important;
  border-color: $dm-border-color-light !important;
  h3 {
    color: $dm-text-light !important;
  }
}
.mint-logo {
  background-image: url('../images/mint-dark.svg') !important;
}
.drop-area {
  border-color: $dm-border-color-light !important;
}

// Transaction logs
.log-container {
  .log-details {
    span.log-header {
      color: $dm-text-light;
    }
    .log-items {
      span.log-item {
        color: $dm-text-lighter;
      }
      .monospace {
        color: $dm-text-lightest;
      }
    }
  }
  .log-icon-line {
    .mid {
      background-color: $dm-background-lighter !important;
    }
  }
}
